.section-concept {
  padding: 60px 0 0;
  overflow: hidden;

  @include mobile {
    padding: 30px 0 40px;
  }

  .headline {
    padding-left: 5.3077%;
    margin-bottom: 100px;

    @include mobile {
      padding-left: 0;
      margin-bottom: 47px;
    }
  }

  .medical-list {
    padding: 0 20px;

    @include tab {
      padding: 0;
      margin-top: 35px;
    }

    &__bg {
      background-color: $color-wht;
      border-radius: 10px;
      padding: 35px 30px 45px;
      box-shadow: 20px 20px 15px rgba(0, 0, 0, 0.03);
      position: relative;
      top: -68px;

      @include tab {
        top: 0;
        padding: 22px 23px 26px;
        box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.03);
      }
    }

    &__title {
      font-size: 1.4rem;
      color: $color-brown-500;
      text-align: center;
      margin-bottom: 28px;
    }

    &__list {
      display: flex;

      @include tab {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
      }
    }

    &__item {
      width: 171px;

      @include tab {
        width: auto;
        border-bottom: 1px solid $color-beige-300;
      }

      + .medical-list__item {
        border-left: 1px solid $color-gold-300;

        @include tab {
          border-left: none;
        }
      }
    }
  }

  .medical-item {
    padding: 12px 10px 14px;
    text-align: center;

    @include tab {
      padding: 10px 5px;
    }

    &__ja {
      font-size: 1.6rem;
      color: $color-brown-500;
      margin-bottom: 8px;

      @include tab {
        font-size: 1.2rem;
        margin-bottom: 5px;
      }
    }

    &__en {
      font-size: 1rem;
      letter-spacing: 0.1em;
      color: $color-gold-300;

      @include tab {
        font-size: 1rem;
        transform: scale(0.7);
        margin: 0 -15%;
      }
    }
  }

  .heading__bg {
    left: 11.3333vw;

    @include mobile {
      left: -13.3333vw;
    }
  }
}
