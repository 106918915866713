.section-hero {
  .content {
    position: relative;
  }

  .catch-copy {
    position: absolute;
    bottom: 7%;
    left: 6.6666%;
    z-index: 1;
    color: $color-gold-300;
    font-family: $font__cinzel;
    line-height: 1;

    @include mobile {
      left: 1.7333%;
      bottom: 9%;
      line-height: 1.15;
    }

    > * {
      transform-origin: top;
    }

    &__first-line {
      display: flex;
      align-items: center;
    }

    &__first-line-main {
      font-size: 10vw;
      letter-spacing: 0.075em;

      @include mobile {
        font-size: 13.3334vw;
      }
    }

    &__first-line-sub {
      font-size: 1.6vw;
      margin-left: 1em;
      letter-spacing: 0.2em;
      font-weight: 600;
    }

    &__second-line {
      font-size: 12vw;
      letter-spacing: 0.075em;

      @include mobile {
        font-size: 14.6667vw;
      }
    }

    &__sub {
      font-size: 1.2rem;
      color: $color-wht;
      margin-top: 10px;
      font-weight: 600;
      text-indent: 1em;
    }
  }

  .hero {
    &__img {
      height: 100vh;
      min-height: 45vw;
      max-height: 100vw;
      position: relative;
      z-index: 0;

      @include mobile {
        max-height: 100vw;
        height: 500px;
        min-height: auto;
        max-height: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .hero-slide {
    height: 100%;
  }
}
