.section-clinic-data {
  padding: 119px 0 191px;
  overflow: hidden;

  @include mobile {
    padding: 80px 0 47px;
  }

  .headline {
    display: flex;
    justify-content: end;
    padding-right: 9%;
    margin-bottom: 200px;

    @include mobile {
      padding-right: 0;
      margin-bottom: 95px;
    }
  }

  .content {
    color: $color-brown-500;

    .media-data:first-of-type {
      margin-bottom: 100px;

      @include mobile {
        margin-bottom: 65px;
      }
    }
  }

  .media-data {
    display: flex;
    align-items: center;

    @include mobile {
      display: block;
    }

    &__img {
      width: 50%;

      img {
        width: 100%;
      }

      @include mobile {
        width: auto;
        margin-bottom: 28px;
      }
    }

    &__body {
      width: 50%;
      padding: 0 3.8462% 0 4.6144%;

      @include mobile {
        width: auto;
        padding: 0 15px;
      }
    }

    &__title {
      letter-spacing: 0.1em;
      margin-bottom: 30px;

      @include mobile {
        margin-bottom: 12px;
      }
    }

    &__term {
      font-size: 2.1rem;

      @include mobile {
        font-size: 1.1rem;
      }
    }

    &__desc {
      font-size: 4.8rem;
      margin-left: 5px;

      @include mobile {
        font-size: 2.4rem;
        margin-left: 0;
      }
    }

    &__note {
      min-height: 90px;

      @include mobile {
        min-height: auto;
        font-size: 0.9rem;
        line-height: 2;
      }
    }

    &__text {
      margin-top: 45px;
      min-height: 258px;
      font-size: 1.6rem;

      @include mobile {
        margin-top: 18px;
        min-height: auto;
        font-size: 1.2rem;
        line-height: 2;
      }
    }

    &_reverse {
      flex-direction: row-reverse;

      .media-data {
        &__body {
          padding: 0 4.6144% 0 3.8462%;
        }
      }
    }
  }

  .member {
    font-size: 1.8rem;
    text-align: center;
    padding: 31px 20px;
    max-width: 800px;
    margin: 113px auto 0;
    border: {
      top: 1px solid $color-gold-300;
      bottom: 1px solid $color-gold-300;
    }

    @include mobile {
      font-size: 1.2rem;
      padding: 11px 20px;
      max-width: 500px;
      margin-top: 40px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: -20px;

      @include mobile {
        margin-bottom: -4px;
      }

      .member__item:not(:last-of-type) {
        margin-right: 60px;
        position: relative;

        @include mobile {
          margin-right: 30px;
        }

        &::before {
          content: '|';
          position: absolute;
          top: calc(50% - 2px);
          right: -30px;
          transform: translate(50%, -50%);

          @include mobile {
            right: -15px;
          }
        }
      }
    }

    &__item {
      display: flex;
      margin-bottom: 20px;

      @include mobile {
        margin-bottom: 4px;
      }
    }
  }

  .recruit {
    margin-top: 150px;

    @include mobile {
      margin-top: 75px;
    }

    &__head {
      margin-bottom: 40px;

      @include mobile {
        margin-bottom: 25px;
      }
    }

    &__body {
      text-align: center;
      padding: 0 15px;
    }

    &__text {
      font-size: 1.8rem;
      font-weight: 400;
      letter-spacing: 0.05em;
      text-align: center;
      line-height: 2;
      margin-bottom: 55px;

      @include mobile {
        font-size: 1.2rem;
        text-align: justify;
        margin-bottom: 30px;
      }
    }
  }

  .reservation {
    margin-top: 212px;
    padding: 70px 40px 85px;
    box-shadow: 20px 20px 15px rgba(0, 0, 0, 0.02),
      -10px -10px 15px rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    background-color: $color-wht;

    @include mobile {
      box-shadow: 10px 10px 8px rgba(0, 0, 0, 0.02),
        -5px -5px 8px rgba(255, 255, 255, 0.5);
      border-radius: 5px;
      padding: 37px 40px 29px;
      margin-top: 85px;
    }

    &__head {
      margin-bottom: 22px;

      @include mobile {
        margin-bottom: 15px;
      }
    }

    &__text {
      font-size: 2.1rem;
      margin-bottom: 55px;
      text-align: center;

      @include mobile {
        font-size: 1.2rem;
        margin-bottom: 0;
        line-height: 1.8;
      }
    }

    &__2cols {
      display: flex;
      justify-content: center;

      @include mobile {
        display: block;
      }

      > * + * {
        margin-left: 40px;

        @include mobile {
          margin-left: 0;
        }
      }
    }

    .tel-btn,
    .reservation-btn {
      min-height: 80px;
      max-width: 450px;
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: inherit;
      font-size: 2.4rem;
      padding: 0.5em;
      transition: opacity 0.4s;

      &:hover,
      &:focus {
        opacity: 0.7;
      }

      @include mobile {
        min-height: 50px;
        max-width: none;
        font-size: 1.2rem;
      }
    }

    .tel-btn {
      color: $color-brown-500;
      background-color: $color-wht;

      @include mobile {
        min-height: auto;
        margin-bottom: 10px;
      }
    }

    .reservation-btn {
      color: $color-wht;
      background-color: $color-orange-500;
    }
  }

  .heading__bg {
    left: -83vw;

    @include mobile {
      left: -138vw;
    }
  }
}
