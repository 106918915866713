.section-vision {
  padding: 70px 0 60px;
  overflow: hidden;

  @include mobile {
    padding: 40px 0 47px;
  }

  .headline {
    display: flex;
    justify-content: end;
    padding-right: 21.2308%;
    margin-bottom: 100px;

    @include mobile {
      padding-right: 0;
      margin-bottom: 50px;
    }
  }

  .heading__bg {
    left: -66.6vw;

    @include mobile {
      left: -125vw;
    }
  }
}
