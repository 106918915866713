.section-info {
  padding-bottom: 46px;

  @include mobile {
    padding-bottom: 0;
  }

  .section-content {
    &__bg {
      background-color: $color-wht;
      padding: 40px 50px;
      display: flex;
      position: relative;
      top: -14px;
      z-index: 1;

      @include mobile {
        padding: 18px 25px 21px;
        top: -25px;
      }
    }
  }

  .headline {
    width: 266px;
    padding-right: 30px;

    @include mobile {
      display: none;
    }

    &__title {
      font-family: $font__cinzel;
      font-size: 2.4rem;
      letter-spacing: 0.05em;
      color: $color-brown-500;
    }
  }

  .content {
    width: 936px;
  }

  .news-list {
    padding-top: 8px;

    @include mobile {
      padding-top: 0;
    }

    &__item {
      + .news-list__item {
        margin-top: 20px;

        @include mobile {
          margin-top: 5px;
        }
      }
    }
  }

  .stack {
    &__link {
      display: flex;
      align-items: center;
      transition: opacity 0.4s;

      &:hover,
      &:focus {
        opacity: 0.5;
      }

      @include mobile {
        flex-wrap: wrap;
      }
    }

    &__time {
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      font-family: $font__cinzel;
      margin-right: 4.2735%;

      @include mobile {
        font-size: 0.6rem;
        width: 100%;
        margin-right: 0;
      }
    }

    &__time,
    &__category {
      white-space: nowrap;
    }

    &__category,
    &__title {
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.05em;

      @include mobile {
        font-size: 1.1rem;
      }
    }

    &__category {
      margin-right: 0.5em;
    }
  }
}
