.section-access {
  padding: 85px 0 120px;
  background-color: $color-wht;
  color: #7c6a5a;

  @include mobile {
    padding: 40px 0 60px;
  }

  .headline {
    margin-bottom: 10px;

    @include mobile {
      margin-bottom: 3px;
    }
  }

  .address {
    text-align: center;
    font-weight: 400;
    margin-bottom: 55px;

    @include mobile {
      margin-bottom: 29px;
    }

    &__address {
      font-size: 1.4rem;

      @include mobile {
        font-size: 1rem;
      }
    }

    &__tel {
      font-size: 1.8rem;
      margin-top: 3px;

      @include mobile {
        font-size: 1.2rem;
        // margin-top: 0;
      }
    }
  }

  .map {
    position: relative;
    padding-top: 46.1538%;

    @include mobile {
      padding-top: 108.3333%;
    }

    > iframe {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
    }
  }
}
