.wrap {
  overflow: hidden;
  background-color: $color-beige-100;
}

.heading {
  position: relative;
  z-index: 0;
  padding-top: 85px;
  padding-left: 79px;

  @include mobile {
    padding-top: 42px;
    padding-left: 39px;
  }

  &__num-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 142px;
    height: 142px;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    color: $color-orange-400;

    @include mobile {
      width: 71px;
      height: 71px;
    }
  }

  &__num {
    font-size: 12rem;
    font-family: $font__cinzel;
    line-height: 0.9;
    margin-left: 24px;
    transform-origin: top;

    @include mobile {
      font-size: 6rem;
      margin-left: 12px;
    }
  }

  &__num-line {
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 2;
    width: 201px;
    height: 2px;
    background-color: currentColor;
    transform-origin: left;
    transform: rotate(-45deg);

    @include mobile {
      width: 100px;
    }
  }

  &__title {
    color: $color-brown-500;
    letter-spacing: 0.05em;
    font-family: $font__cinzel;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  &__title-sub {
    font-size: 1.2rem;
    display: block;
    padding-left: 0.5em;
    margin-bottom: 3px;

    .char {
      display: inline-block;
    }

    @include mobile {
      font-size: 1rem;
      transform-origin: left bottom;
      transform: scale(0.6);
      width: 0;
      white-space: nowrap;
    }
  }

  &__title-main {
    font-size: 4.8rem;
    display: block;
    line-height: 1;
    transform-origin: top;

    @include mobile {
      font-size: 2.4rem;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__bg-text-wrap {
    display: flex;

    > * {
      margin-right: 50vw;
    }
  }

  &__bg-text {
    font-size: 35rem;
    font-family: $font__cinzel;
    color: $color-gold-300;
    opacity: 0.2;
    line-height: 1;
    white-space: nowrap;

    @include mobile {
      font-size: 17.5rem;
    }
  }
}

.section {
  .content {
    position: relative;
    z-index: 1;
  }
}

.media {
  display: flex;
  align-items: center;

  @include mobile {
    display: block;
  }

  &__img {
    width: 50%;

    img {
      width: 100%;
    }

    @include mobile {
      width: auto;
      margin-bottom: 27px;
    }
  }

  &__body {
    width: 50%;
    padding: 0 3.8462% 9.2308% 4.6144%;

    @include mobile {
      width: auto;
      padding: 0 15px;
    }
  }

  &__title {
    font-size: 3rem;
    letter-spacing: 0.1em;
    margin-bottom: 40px;

    @include mobile {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  }

  &__text {
    @include mobile {
      line-height: 2;
    }
  }

  &_reverse {
    flex-direction: row-reverse;

    .media__body {
      padding: 0 4.6144% 9.2308% 3.8462%;

      @include mobile {
        padding: 0 15px;
      }
    }
  }
}

.title {
  text-align: center;

  &__main {
    display: block;
    font-family: $font__cinzel;
    letter-spacing: 0.05em;
    font-size: 4.8rem;

    @include mobile {
      font-size: 2.4rem;
    }
  }

  &__sub {
    display: block;
    font-size: 1.8rem;

    @include mobile {
      font-size: 1.2rem;
    }
  }
}

.opening {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100vh;
  background: #fff;
}
