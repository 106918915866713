.section-facilities {
  padding: 70px 0 0;
  overflow: hidden;

  @include mobile {
    padding: 47px 0 0;
  }

  .section-content {
    > .row:first-of-type {
      position: relative;
      z-index: 1;
    }
  }

  .headline {
    padding-left: 5.3077%;
    margin-bottom: 50px;

    @include mobile {
      padding-left: 0;
      margin-bottom: 24px;
    }
  }

  .content {
    background-color: #efebde;
    color: #7c6a5a;
    position: relative;
    z-index: auto;
  }

  .media-oblong {
    position: relative;
    z-index: 1;

    &__container {
      padding-top: 40px;
      padding-bottom: 40px;
      display: flex;
      align-items: center;

      @include mobile {
        padding: 30px 40px 40px;
        display: block;
      }
    }

    &__img {
      width: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }

      @include mobile {
        width: auto;
        margin-bottom: 25px;
      }
    }

    &__body {
      width: 50%;
      padding: 0 3.8462% 0% 4.6144%;

      @include mobile {
        width: auto;
        padding: 0;
      }
    }

    &__title {
      font-size: 3rem;
      margin-bottom: 40px;

      @include mobile {
        font-size: 1.8rem;
        margin-bottom: 22px;
      }
    }

    &__text {
      min-height: 150px;

      @include mobile {
        min-height: auto;
        font-size: 1.1rem;
        line-height: 1.9;
      }
    }

    &_reverse {
      background-color: #ebe4d3;

      .media-oblong {
        &__container {
          flex-direction: row-reverse;
        }

        &__body {
          padding: 0% 4.6144% 0 3.8462%;

          @include mobile {
            padding: 0;
          }
        }
      }
    }
  }

  .heading__bg {
    left: 11.3333vw;

    @include mobile {
      left: -13.3333vw;
    }
  }
}
